import axios from "./axios.js"

export class MailFilters {

    static getMailFilters() {
        return axios.get("/mailfilters");

    }
    static getMailFilter(id) {
        let defaultURL = `/mailfilters/${id}`;
        return axios.get(defaultURL);
    }
    static createMailFilter(mailfilter) {
        let defaultURL = `/mailfilters`;
        return axios.post(defaultURL, mailfilter);
    }
    static updateMailFilter(mailfilter, id) {
        let defaultURL = `/mailfilters/${id}`;
        return axios.put(defaultURL, mailfilter);
    }
    static deleteMailFilter(id) {
        let defaultURL = `/mailfilters/${id}`;
        return axios.delete(defaultURL);
    }

}