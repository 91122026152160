<template>
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-6">
                <h3>Mail Recipient</h3>
            </div>
            <div class="col-md-6">
                <!-- <pre>{{ form }} {{ query }}</pre> -->
                <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;" id="btnAdd">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Recipient</button>
                <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;" id="btnHide">
                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                    Hide</button>
            </div>
        </div>
        <div class="row" v-if="seen">
            <div class="col">

                <!-- <pre>{{ form }}</pre> -->
                <form @submit="onSubmit" class="state-from vendor-mas">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="name" class="form-label">Filter Name </label>
                            <input type="text" v-model="form.name" class="form-control" id="name"
                                placeholder="Filter Name" required>
                            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <div class="mb-3">
                        <label for="Remarks" class="form-label">Filter Remarks </label>
                        <input type="text" v-model="form.remarks" class="form-control" id="remarks"
                            placeholder="Filter Remarks" required>
                        <div v-if="errors.remarks" class="invalid-feedback">{{ errors.remarks }}</div>
                         </div>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label for="filterGroupValue" class="form-label">Group Tags</label>
                            <Multiselect id="filterGroupValue" v-model="form.filterGroupValue" :options="groupOptions"
                                mode="tags" :searchable="true" :createTag="true" placeholder="Group Tags" required />
                            <div v-if="errors.filterGroupValue" class="invalid-feedback">{{ errors.filterGroupValue }}
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="filterGrouprator" class="form-label"> Group Filter Oprator </label>
                            <select id="filterGrouprator" v-model="form.filterGrouprator" class="form-select" required>
                                <option selected Disabled>Select Oprator</option>
                                <!-- <option value="None">None </option> -->
                                <!-- <option value="=">= </option>
                                <option value="!=">!= </option>
                                <option value="like">%like% </option> -->
                                <option value="In">In </option>
                                <option value="All">All </option>
                            </select>
                            <div v-if="errors.filterGrouprator" class="invalid-feedback">{{ errors.filterGrouprator }}
                            </div>
                        </div>
                    
               

                        <div class="col-md-4 mb-3">
                            <label for="filterCityValue" class="form-label">City Name</label>
                            <Multiselect id="filterCityValue" v-model="form.filterCityValue" :options="cityOptions"
                                mode="tags" :searchable="true" :createTag="true" placeholder="City Name" required />
                            <div v-if="errors.filterCityValue" class="invalid-feedback">{{ errors.filterCityValue }}
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="filterCityOprator" class="form-label"> City Filter Oprator </label>
                            <select id="filterCityOprator" v-model="form.filterCityOprator" class="form-select"
                                required>
                                <option selected Disabled>Select Oprator</option>
                                <!-- <option value="None">None </option> -->
                                <!-- <option value="=">= </option>
                                <option value="!=">!= </option>
                                <option value="like">%like% </option> -->
                                <option value="In">In </option>
                                <option value="All">All </option>
                            </select>
                            <div v-if="errors.filterCityOprator" class="invalid-feedback">{{ errors.filterCityOprator }}
                            </div>
                        </div>
                    </div>


                    

                    <div class="btn-bottom">
                        <input type="submit" :value="btnText" class="btn  btn-primary" />
                 </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="table-responsive">
            <table class="table" ref="mailFilterTable">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Name</th>
                        <th>filterGrouprator</th>
                        <th>filterCityOprator</th>
                        <th>filterCityValue</th>
                        <th>filterGroupValue</th>
                        <th>remarks</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="mailFilter in mailFilters" :key="mailFilter.id">
                        <td>{{ mailFilter.id }}</td>
                        <td>{{ mailFilter.name }}</td>
                        <td>{{ mailFilter.filterGrouprator }}</td>
                        <td>{{ mailFilter.filterCityOprator }}</td>
                        <td>{{  mailFilter.filterCityValue }}</td>
                        <td>{{ mailFilter.filterGroupValue }}</td>
                        <td>{{ mailFilter.remarks }}</td>
                        <td><button class="btn btn-success btn-sm" @click="editdata(mailFilter.id)"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
                            <button class="btn btn-danger btn-sm" @click="deletedata(mailFilter.id)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>
<style src="@vueform/multiselect/themes/default.css">

</style>
<script>

import { MailFilters } from '../services/MailFilters'
import { City } from '../services/City';
import { Group } from '../services/Group';
import Multiselect from '@vueform/multiselect'
import Spinner from '../components/Spinner.vue';
import $ from "jquery";
import dt from "datatables.net";
export default {
    name: 'MailFilter',
    components: {
        Multiselect,
        Spinner
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                filterGrouprator: '',
                filterCityOprator: '',
                filterGroupValue: [],
                filterCityValue: [],
                remarks: '',

            },
            loading: false,
            mailFilters: [],
            cities: [],
            seen: false,
            groupOptions: [

            ],
            cityOptions: [

            ],
            groups: [],
            errors: [],
            dt: dt,
            btnText: 'Save',
            errorMasage: null
        }
    },
    mounted() {
        this.dt = $(this.$refs.mailFilterTable).DataTable();
    },
    watch: {
        mailFilters() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailFilterTable).DataTable()
            })
        }
    },
    async created() {
        try {
            this.loading = true
            let response = await Group.getGroups()
            // console.log(response.data);
            this.groups = response.data;
            this.groupOptions = this.groups.map(function (group) {
                return {
                    value: group.id,
                    label: group.name
                }
            })
            this.loading = false;
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
        try {
            this.loading = true
            let response = await City.getCities()
            // console.log(response.data);
            this.cities = response.data;
            this.cityOptions = this.cities.map(function (city) {
                return {
                    value: city.id,
                    label: city.cityName
                }
            })
            this.loading = false;
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
        try {
            this.loading = true
            let response = await MailFilters.getMailFilters();
            // console.log(response.data);
            this.mailFilters = response.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    }, methods: {
        checkValidations() {
            var name = document.getElementById("name");
            var filterGrouprator = document.getElementById("filterGrouprator");
            var filterCityOprator = document.getElementById("filterCityOprator");
            var filterGroupValue = document.getElementById("filterGroupValue");
            var filterCityValue = document.getElementById("filterCityValue");
            var remarks = document.getElementById("remarks");

            if (this.errors.name) name.classList.add("is-invalid"); else name.classList.add("is-valid");
            if (this.errors.filterGrouprator) filterGrouprator.classList.add("is-invalid"); else filterGrouprator.classList.add("is-valid");
            if (this.errors.filterCityOprator) filterCityOprator.classList.add("is-invalid"); else filterCityOprator.classList.add("is-valid");
            if (this.errors.filterGroupValue) filterGroupValue.classList.add("is-invalid"); else filterGroupValue.classList.add("is-valid");
            if (this.errors.filterCityValue) filterCityValue.classList.add("is-invalid"); else filterCityValue.classList.add("is-valid");
            if (this.errors.remarks) remarks.classList.add("is-invalid"); else remarks.classList.add("is-valid");
        },
        removeValidations() {
            var name = document.getElementById("name");
            var filterGrouprator = document.getElementById("filterGrouprator");
            var filterCityOprator = document.getElementById("filterCityOprator");
            var filterGroupValue = document.getElementById("filterGroupValue");
            var filterCityValue = document.getElementById("filterCityValue");
            var remarks = document.getElementById("remarks");

            name.classList.remove("is-invalid");
            name.classList.remove("is-valid");
            filterGrouprator.classList.remove("is-invalid");
            filterGrouprator.classList.remove("is-valid");
            filterCityOprator.classList.remove("is-invalid");
            filterCityOprator.classList.remove("is-valid");
            filterGroupValue.classList.remove("is-invalid");
            filterGroupValue.classList.remove("is-valid");
            filterCityValue.classList.remove("is-invalid");
            filterCityValue.classList.remove("is-valid");
            remarks.classList.remove("is-invalid");
            remarks.classList.remove("is-valid");
        },
        async onSubmit(e) {
            e.preventDefault()
            if (!this.form.name) {
                alert('Please Enter Vendor name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    const response = await MailFilters.createMailFilter(this.form);
                    this.$swal(
                        'Mail Filter Created ',
                        response.data.status,
                        'success'
                    );
                    this.form.name = "";
                    this.form.filterGrouprator = "";
                    this.form.filterValue = "";
                    this.form.filterCityOprator = "";
                    this.form.emaiPasswrod = "";
                    this.form.filterCityValue = [];
                    this.form.filterGroupValue = [];
                    this.removeValidations();
                    this.btnText = "Save";
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errorMasage = error.response.data;
                        this.errors = error.response.data.errors;
                        this.checkValidations();
                    }
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    const response = await MailFilters.updateMailFilter(this.form, this.form.id);
                    // console.log(response);
                    this.$swal(
                        'Mail Filter Updated ',
                        response.data.status,
                        'success'
                    );
                    this.form.name = "";
                    this.form.filterGrouprator = "";
                    this.form.filterValue = "";
                    this.form.filterCityOprator = "";
                    this.form.emaiPasswrod = "";
                    this.form.filterCityValue = [];
                    this.form.filterGroupValue = [];
                    this.removeValidations();
                    this.btnText = "Save";
                } catch (error) {
                    console.log("this is error ", error)
                    if (error.response.status === 422) {
                        // console.log(error.response.data.errors.name);
                        this.errors = error.response.data.errors;
                        this.checkValidations();

                    }
                    // console.log(errors.value.name);
                }
                // this.btnText = "Save";
            }
            this.loading = true
            let response = await MailFilters.getMailFilters();
            // console.log(response.data);
            this.mailFilters = response.data;
            this.loading = false


        },
        async editdata(id) {
            // alert(id);
            this.btnText = "Update";
            const response = await MailFilters.getMailFilter(id);
            this.form.id = id;
            this.form.name = response.data.name;
            this.form.filterGrouprator = response.data.filterGrouprator;
            this.form.filterCityOprator = response.data.filterCityOprator;
            this.form.filterValue = response.data.filterValue;
            this.form.emaiPasswrod = response.data.emaiPasswrod;
            this.form.remarks = response.data.remarks;
            this.form.filterGroupValue = response.data.filterGroupValue.split(",");
            this.form.filterCityValue = response.data.filterCityValue.split(",");

            // console.log(response.data.name);
            // this.btnText = "Save";
            this.seen = true;
        },
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailFilters.deleteMailFilter(id);
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Mail Template Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailFilters.getMailFilters();
            this.templates = response.data;
            this.loading = false;
        },
        async cityIdsToName($ids){
            let form = new FormData();
            form.append("query",$ids);
            let response = await City.cityIdsToName(form);
            let cityNameList = response.data
            // console.log(cityNameList);
            let cityNames = "";
            cityNameList.forEach(city => {
                
                cityNames = cityNames + city.cityName + ' ,';
                
            });
            // console.log('city',cityNames);
            return cityNames;
        }
    },
}
</script>
<style scoped>
.vendor-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}
.vendor-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;
 
}
.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}
.btn-bottom {
    text-align: center;
}
.vendor-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}
.vendor-mas .form-select {
    padding: 12px 10px;
    border-radius: 0;

}
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF; vertical-align: middle;}
tr.even { background-color:#e5f5ff; vertical-align: middle;}
</style>